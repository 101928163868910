@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');
body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #686868;
    font-size: 16px;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lora', serif;
    color: #523906;
    margin-top: 0px;
    font-weight: 700;
    text-transform: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #523906;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    color: #523906;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 35px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

ul {
    margin: 0px;
    padding: 0px;
}

li {
    list-style: none;
}

p {
    font-size: 16px;
    font-weight: normal;
    line-height: 28px;
    color: #686868;
    margin-bottom: 15px;
}

input,
select {
    transition: 0.3s;
}

hr {
    border-bottom: 1px solid #eceff8;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}

label {
    color: #7e7e7e;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

.img,
img {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a,
.button,
button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none;
}

a:focus {
    text-decoration: none;
}

a:hover {
    color: #ec6e39;
}

a,
button {
    color: inherit;
    outline: medium none;
    text-decoration: none;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
    outline: 0;
}

*::-moz-selection {
    background: #222;
    color: #ffffff;
    text-shadow: none;
}

::-moz-selection {
    background: #222;
    color: #ffffff;
    text-shadow: none;
}

::selection {
    background: #222;
    color: #ffffff;
    text-shadow: none;
}

*::-moz-placeholder {
    color: #523906;
    font-size: 16px;
    opacity: .60;
}

*::placeholder {
    color: #523906;
    font-size: 16px;
    opacity: .60;
}

[data-overlay="1"]::before {
    background-color: #523906;
}

[data-overlay="2"]::before {
    background-color: #cdb30c;
}

[data-overlay="black"]::before {
    background-color: #000;
}

.scroll-to-top {
    bottom: 100px!important;
    right: 175px!important;
    width: 50px!important;
    height: 61px!important;
    line-height: 61px!important;
    font-size: 18px!important;
    text-align: center!important;
    border-radius: 20px 20px 0 0!important;
    background-color: #cdb30c!important;
    color: #fff!important;
}
ssss
/**
*   03. Common Classes
*/

.fix {
    overflow: hidden;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.clear {
    clear: both;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.text-right {
    text-align: right;
}

.height-592 {
    height: 592px;
}

.height-800 {
    height: 800px;
}

.height-680 {
    height: 680px;
}

.bg-property {
    background-color: #ddd;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-hover-style-1 {
    position: relative;
}

.bg-hover-style-1:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #523906;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-project:hover .bg-hover-style-1:before,
.works-content-wrapper:hover .bg-hover-style-1:before,
.single-blog-wrapper:hover .bg-hover-style-1:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #523906;
    position: absolute;
    opacity: .75;
    visibility: visible;
    transition: .3s;
}

.white-bg {
    background: #ffffff;
}

.gray-bg {
    background: #f1f1f1;
}

.gray-bg-2 {
    background: #f9f9f9;
}

.bg-1 {
    background: #523906;
}

.theme-bg-2 {
    background: #3a2701;
}

.theme-bg-3 {
    background: #cdb30c;
}

.bg-4 {
    background: #faf7e6;
}

.b-color-1 {
    border-color: #f2ecc2;
}

.b-color-2 {
    border-color: #523906;
}

.box-shadow-1 {
    box-shadow: 0px 3px 81px 0px rgba(6, 6, 6, 0.09);
}

.box-shadow-2 {
    box-shadow: 0px 3px 81px 0px rgb(6 6 6 / 9%);
}

.box-shadow-3 {
    box-shadow: 0px 3px 81px 0px rgba(0, 0, 0, 0.09);
}

.white-color {
    color: #ffffff;
}

.l-btn {
    background-color: #cdb30c;
    color: #fff;
    font-family: 'Lora', serif;
    font-size: 18px;
    font-weight: 700;
    padding: 22px 40px;
    border-radius: 50px;
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
    border: none;
}

.l-btn:hover {
    background-color: #523906;
    color: #fff;
}


/**
*   04. Section Title
*/

.section-title span {
    display: block;
    font-size: 18px;
    color: #cdb30c;
    line-height: 28px;
    margin-bottom: 30px;
}

.section-title h2 {
    font-size: 46px;
    line-height: 56px;
}


/**
*   05. Header
*/

.header-info ul li {
    color: #f1f1f1;
    margin-right: 40px;
    display: inline-block;
}

.header-info ul li svg {
    color: #cdb30c;
    margin-right: 10px;
    margin-top: -3px;
}

.header-social a {
    color: #f1f1f1;
    margin-left: 17px;
}

.header-info ul li a:hover,
.header-social a:hover {
    color: #cdb30c;
}

.header-btn {
    padding: 22px 0;
}

.header-call-btn {
    padding: 18px 0;
}

.main-menu ul li {
    display: inline-block;
    margin-left: 45px;
    position: relative;
}

.main-menu ul li a {
    color: #523906;
    font-size: 18px;
    font-weight: 600;
    display: block;
    padding: 40px 0;
}

.main-menu ul li:hover>a,
.main-menu ul li .submenu li:hover>a {
    color: #cdb30c;
}

.main-menu ul li .submenu {
    position: absolute;
    background-color: #fff;
    width: 230px;
    padding: 15px 25px;
    border-top: 4px solid #cdb30c;
    top: 110%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    z-index: 3;
}

.main-menu ul li:hover>.submenu {
    top: 100%;
    opacity: 1;
    visibility: visible;
    transition: .3s;
}

.main-menu ul li .submenu li {
    display: block;
    margin-left: 0px;
    text-align: left;
    transition: .3s;
}

.main-menu ul li .submenu li:hover {
    padding-left: 10px;
    transition: .3s;
}

.main-menu ul li .submenu li a {
    display: block;
    color: #523906;
    padding: 8px 0;
}

.header-call-btn img {
    margin-right: 12px;
}

.header-call-btn a {
    font-size: 24px;
    font-weight: 700;
    line-height: 70px;
    color: #523906;
    font-family: 'Lora', serif;
}

.header-call-btn a:hover {
    color: #cdb30c;
}
.sticky-top {
    background-color: white;
}

/**
*   06. Hero area
*/


/*Style 1*/

.hero-wrapper h1 {
    font-size: 75px;
    font-weight: 700;
    line-height: 85px;
    color: #fff;
    margin-bottom: 40px;
}

.hero-wrapper p {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 30px;
}

.hero-popup-content img {
    width: 400px;
    border-radius: 350px 0px 350px 350px;
    border-width: 20px;
    border-style: solid;
    border-color: #cdb30c;
}

.hero-popup-content {
    position: relative;
    padding: 20px;
    margin-left: 200px;
}

.hero-popup-content:before {
    content: '';
    display: block;
    width: 440px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 350px 0 350px 350px;
    border-width: 20px;
    border-style: solid;
    border-color: #fff;
}

button.popup-video {
    display: block;
    background-color: #fbfbfa;
    color: #cdb30c;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 24px;
    border-radius: 50px;
    position: absolute;
    top: 40%;
    left: 44%;
    border: none;
}

.pulse-effect {
    animation: pulse-effect 1s infinite;
}

@keyframes pulse-effect {
    0% {
        box-shadow: 0 0 0 0px #fff;
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}


/*Style 2*/

.hero-slider-1 .single-slider-style-1 {
    position: relative;
    z-index: 2;
}

.hero-slider-1 .single-slider-style-1:before {
    content: '';
    display: block;
    background-image: linear-gradient( 0deg, rgb(82, 57, 6) 0%, rgba(82, 57, 6, 0.5) 47%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%);
    background-image: -moz-linear-gradient( 0deg, rgb(82, 57, 6) 0%, rgba(82, 57, 6, 0.5) 47%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(82, 57, 6) 0%, rgba(82, 57, 6, 0.5) 47%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(82, 57, 6) 0%, rgba(82, 57, 6, 0.5) 47%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%);
    position: absolute;
    left: 0px;
    top: 0;
    width: 1920px;
    height: 100%;
    z-index: -1;
}

.hero-slider-1 .slide-content-wrapper-1 h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 35px;
}

.hero-slider-1 .slide-content-wrapper-1 p {
    color: #fff;
    font-size: 20px;
    margin-bottom: 35px;
}

.hero-slider-1 ul.slick-dots li {
    display: inline-block;
    margin-left: 10px;
}

.hero-slider-1 ul.slick-dots {
    position: absolute;
    bottom: 10%;
    right: 18%;
}

.hero-slider-1 ul.slick-dots li button {
    background: transparent;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Lora', serif;
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
}

.hero-slider-1 ul.slick-dots li.slick-active button {
    background-color: #fff;
    border: 2px solid #fff;
    color: #523906;
}

.hero-slider-1 .slick-next {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 30px;
    position: absolute;
    top: 50%;
    right: 17%;
    z-index: 1;
}

.hero-slider-1 .slick-prev {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 12%;
    z-index: 1;
}


/*Style 3*/

.hero-slider-2 .slide-content-wrapper-2 h1 {
    font-size: 56px;
    line-height: 66px;
    color: #fff;
    margin-bottom: 40px;
}

.hero-slider-2 .slide-content-wrapper-2 p {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 35px;
}

.hero-slider-box {
    z-index: 2333;
    margin-top: -15px;
    margin-bottom: -15px;
}

.slick-active.slick-current .single-slider-box {
    background-color: #cdb30c;
}

.hero-slider-box .slick-next,
.hero-slider-box .slick-prev {
    position: absolute;
    background-color: #fff;
    color: #523906;
    font-size: 18px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border: none;
}

.hero-slider-box .slick-next {
    top: 50px;
    right: 0;
    border-radius: 50px 50px 0px 50px;
}

.hero-slider-box .slick-prev {
    top: 50px;
    left: 88%;
    border-radius: 0px 50px 50px 50px;
}

.hero-slider-box .slick-prev:hover,
.hero-slider-box .slick-next:hover {
    background-color: #cdb30c;
    color: #fff;
}


/**
*   07. Service
*/

.single-service {
    background-color: #fff;
    padding: 25px 25px 30px 25px;
    margin-bottom: 25px;
    transition: .3s;
}

.single-service:hover {
    background-color: #cdb30c;
    color: #fff;
    transition: .3s;
}

.single-service:hover h3,
.single-service:hover p,
.single-service:hover a {
    color: #fff;
}

.service-icon {
    background-color: #faf7e6;
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-left: 21px;
    margin: 30px 0 35px 21px;
}

.service-icon img,
.service-icon i {
    transform: rotate(-45deg);
}

.service-icon i {
    font-size: 40px;
}

.single-service h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 25px;
}

.single-service p {
    margin-bottom: 20px;
}

.single-service a {
    font-size: 16px;
    line-height: 30px;
    color: #cdb30c;
}

.single-service a svg {
    margin-left: 7px;
    margin-top: -1px;
}

.single-service-large {
    background-color: #cdb30c;
    padding: 46px 30px;
}

.single-service-large h3 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #fff;
}

.single-service-large h3 span {
    font-weight: 700;
}

.single-service-large p {
    color: #fff;
    margin-bottom: 50px;
}

.single-service-large .l-btn {
    background-color: #523906;
}

.service-readmore-icon {
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-service:hover .service-readmore-icon {
    opacity: 1;
    visibility: visible;
    transition: .3s;
}


/**
*   08. About
*/


/*Style 1*/

.about-wrapper-style-1 {
    position: relative;
}

.about-secondary {
    position: absolute;
    top: 250px;
    right: 0;
}

.service-bullet-list ul li {
    margin-bottom: 15px;
}

.service-bullet-list ul li svg {
    margin-right: 10px;
    color: #cdb30c;
}


/* Style 2 */

.about-img-style-1 {
    position: relative;
    z-index: 2;
}

.about-img-style-1 img {
    width: 100%;
    padding-right: 110px;
    border-radius: 10px;
}

.about-img-style-1:before {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    height: 85%;
    background-color: #cdb30c;
    z-index: -1;
    top: 80px;
    right: 80px;
    border-radius: 10px;
}

.about-award {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-35%, -50%);
    background: #c8b224;
    width: 220px;
    height: 220px;
    text-align: center;
    border-radius: 100%;
    opacity: .85;
}

.about-award h3 {
    font-size: 24px;
    color: #fff;
    padding: 70px 0;
    line-height: 34px;
}


/* Style 3*/

.about-img-style-2 {
    position: relative;
    z-index: 2;
}

.about-img-style-2:before {
    content: '';
    display: block;
    width: 100%;
    height: 90%;
    background-color: #cdb30c;
    position: absolute;
    top: 10%;
    left: -40px;
    z-index: -1;
}

.about-experience {
    position: absolute;
    left: -160px;
    bottom: 0;
}

.about-experience h2 {
    font-size: 46px;
}

.about-experience span {
    font-size: 18px;
    color: #523906;
}

.about-small-img {
    position: absolute;
    top: 20%;
    left: -160px;
    border-width: 12px 12px 12px 0px;
    border-style: solid;
    border-color: #fff;
}

.about-author-avatar-wrapper img {
    border-radius: 100px;
    width: 70px;
    height: 70px;
    float: left;
    margin-right: 20px;
}

.about-author-avatar-content {
    float: left;
    overflow: hidden;
}

.about-author-avatar-content h5 {
    margin-bottom: 10px;
}

.about-author-avatar-content span {
    color: #cdb30c;
}


/* Style 4*/

.about-block h2 {
    font-size: 46px;
    line-height: 56px;
    margin-bottom: 30px;
}

.experience-area {
    position: relative;
}

.single-experience {
    background-color: #f9f9f9;
    padding: 25px;
    margin-bottom: 25px;
}

.single-experience h4 {
    font-size: 24px;
    padding: 15px 30px;
    background-color: #523906;
    color: #fff;
    display: inline-block;
    margin-bottom: 16px;
}

.single-experience p {
    margin-bottom: 0;
}

.single-experience:hover {
    background-color: #cdb30c;
    transition: .3s;
}

.single-experience:hover p {
    color: #fff;
}

.experience-img {
    position: relative;
}

.experience-img img {
    width: 100%;
}

.experience-small-block {
    position: absolute;
    left: 30px;
    bottom: 30px;
    background-color: #cdae20;
    width: 200px;
    height: 140px;
    padding: 20px;
}

.experience-small-block h5 {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0;
}


/**
*   09. Fun Fact
*/

.single-counter h2 {
    color: #fff;
    font-size: 46px;
    margin-bottom: 20px;
}

.single-counter p {
    color: #fff;
    font-size: 20px;
}


/**
*   10. Project
*/

.single-project {
    transition: .3s;
}

.single-project-img {
    height: 285px;
    position: relative;
    transition: .3s;
}

.single-project-img-icon {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

.single-project:hover .single-project-img-icon {
    opacity: 1;
    visibility: visible;
}

.single-project-img-icon a svg {
    font-size: 14px;
    color: #523906;
    margin: 13px 0;
}

.single-project-content h3 {
    margin-bottom: 20px;
}

.single-project-content p {
    margin-bottom: 0px;
}

.single-project-content {
    background-color: #faf7e6;
    padding: 50px 30px 35px 30px;
    border-bottom: 3px solid;
    border-color: #523906;
}

.single-project:hover .single-project-content {
    background-color: #cdb30c;
    transition: .3s;
    border-color: #cdb30c;
}

.single-project:hover .single-project-content h3,
.single-project:hover .single-project-content p {
    color: #fff;
}



/**
*   11. Why Choose Us
*/

.single-choose-us {
    background-color: #fff;
    padding-top: 53px;
    padding-bottom: 53px;
    box-shadow: 0px 3px 81px 0px rgba(6, 6, 6, 0.09);
}

.single-choose-us-content {
    padding: 0 25px 40px 25px;
}

.single-choose-us-icon {
    padding: 45px 25px 0 25px;
}

.single-choose-us-content h3 {
    font-size: 20px;
    margin-bottom: 25px;
}


/**
*   12. Testimonial
*/

.single-testimonial {
    background-color: #fff;
    padding: 36px 56px;
    margin-bottom: 20px;
    text-align: center;
}

.single-testimonial img {
    width: 110px;
    border-radius: 100%;
    margin-bottom: 30px!important;
    margin: 0 auto;
}

.single-testimonial h3 {
    font-size: 20px;
    margin-bottom: 16px;
}

.single-testimonial span {
    display: block;
    color: #cdb30c;
    margin-bottom: 25px;
    font-size: 18px;
}

.single-testimonial p {
    margin-bottom: 18px;
}

.star-rating svg {
    color: #cdb30c;
    margin: 0 2px;
    font-size: 18px;
}

.testimonial-slider ul.slick-dots {
    text-align: center;
    margin-top: 50px;
}

.testimonial-slider ul.slick-dots button {
    width: 27px;
}

.testimonial-slider ul.slick-dots li button {
    background-color: #523906;
    border: none;
    width: 23px;
    height: 7px;
    border-radius: 50px;
}

.testimonial-slider ul.slick-dots li {
    list-style: none;
    display: inline-block;
    margin-right: 10px;
    font-size: 0;
}

.testimonial-slider ul.slick-dots li.slick-active button {
    width: 36px;
    height: 7px;
    background-color: #ccb20c;
}

.testimonial-slider .slick-slide {
    margin: 0 10px;
    width: 412px!important;
}

.testimonial-img img {
    border-radius: 10px 350px 350px 350px;
    border-width: 10px;
    border-style: solid;
    border-color: #cdb30c;
}


.testimonial-quote-icon svg {
    font-size: 36px;
    color: #fff;
    line-height: 170px;
    margin: 67px 0;
}


/**
*   13. Book Appointment
*/

.book-appointment {
    position: relative;
}

.book-appointment-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}

.book-quote-widget input[type="text"],
.book-quote-widget input[type="email"] {
    margin-right: 20px;
}

.book-quote-widget input[type="text"],
.book-quote-widget input[type="email"],
.book-quote-widget textarea {
    background-color: #f1f1f1;
    border: none;
    padding: 20px 18px;
    width: 100%;
    margin-bottom: 18px;
}

a.l-btn.quote-btn {
    width: 100%;
    border-radius: 0;
    padding: 20px 17px;
}

a.l-btn.quote-btn svg {
    float: right;
}


/**
*   14. How It Works
*/

.how-it-works-icon {
    display: inline-block;
    background-color: #cdb30c;
    width: 130px;
    height: 130px;
    line-height: 130px;
    border-radius: 100px;
    text-align: center;
}

.works-icon {
    border-top: 4px;
    border-right: 1px;
    border-bottom: 3px;
    border-left: 6px;
    border-style: solid;
    border-color: #cdb30c;
    display: inline-block;
    padding: 5px;
    border-radius: 100px;
}

.works-icon img {
    background-color: #cdb30c;
    padding: 30px;
    border-radius: 100px;
    width: 130px;
    height: 130px;
}

.works-icon:before {
    content: '';
    display: block;
}

.works-menu .nav-item {
    display: inline-block;
    margin: 0 60px;
}

.works-menu .nav {
    display: inline-block;
}

.works-menu .nav-pills .nav-link {
    padding: 0;
}

.works-menu .nav-pills .nav-link.active {
    background-color: transparent;
}

.works-menu a {
    border: none;
    background-color: transparent;
}

.works-content-wrapper h3 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;
}

.works-menu .active .works-icon {
    border-color: #523906;
}

.works-menu .active .works-icon img {
    background-color: #523906;
}

.works-img img {
    width: 100%;
    border-radius: 10px;
}

.works-link {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.works-content-wrapper:hover .works-link {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    transition: .3s;
}


/**
*   15. Blog
*/

.single-blog-wrapper {
    background-color: #fff;
    transition: .3s;
}

.blog-details img,
.blog-img img {
    width: 100%;
}

.blog-img a {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-blog-wrapper:hover .blog-img a {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    transition: .3s;
}

.blog-content h4 a {
    display: block;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
}

.blog-content h2 a {
    display: block;
    margin-bottom: 40px;
}

ul.cat-list li {
    display: inline-block;
    margin-right: 17px;
}

ul.cat-list li a {
    color: #523906;
}

ul.cat-list li.parent-cat {
    position: relative;
    padding-left: 20px;
}

ul.cat-list li.parent-cat a {
    color: #cdb30c;
}

ul.cat-list li.parent-cat:before {
    content: '';
    display: block;
    width: 4px;
    height: 20px;
    border-radius: 50px;
    background-color: #cdb30c;
    position: absolute;
    top: 0;
    left: 0;
}

ul.post-meta li {
    display: inline-block;
}

ul.post-meta li a {
    color: #686868;
}

ul.post-meta li:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: #523906;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 7px;
}

ul.post-meta li:last-child:after {
    display: none;
}

ul.post-meta li {
    position: relative;
    padding-right: 22px;
}

a.l-read-more {
    color: #cdb30c;
}

a.l-read-more svg {
    margin-left: 5px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-blog-wrapper:hover a.l-read-more svg {
    opacity: 1;
    visibility: visible;
    transition: .3s;
}

.pagination ul li {
    display: inline-block;
}

.pagination ul li {
    margin: 0 3px;
}

.pagination ul li a {
    display: block;
    background-color: #cdb30c;
    color: #fff;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: 'lora', serif;
}

.pagination ul li a:hover {
    background-color: #523906;
    color: #fff;
}

.pagination ul li span.current {
    display: block;
    background-color: #523906;
    color: #fff;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: 'lora', serif;
}


/**
*   16. Footer
*/

.footer-title {
    margin-bottom: 50px;
}

.footer-widget-1 {
    padding-right: 130px;
}

.footer-widget-4 {
    padding-left: 130px;
}

.footer-title h4 {
    font-size: 24px;
    color: #fff;
}

.footer-text p {
    color: #eeeeee;
}

.footer-info a {
    display: block;
    font-size: 20px;
    color: #eeeeee;
    margin-bottom: 28px;
}

.footer-info a svg {
    color: #cdb30c;
    margin-right: 10px;
}

.footer-menu ul li {
    margin-bottom: 20px;
}

.footer-menu ul li a {
    display: block;
    color: #eeeeee;
}

.footer-menu ul li a:hover {
    color: #cdb30c;
}

.footer-social h5 {
    display: inline-block;
    color: #cdb30c;
    margin-right: 15px;
    font-size: 20px;
}

.footer-social ul,
.footer-social ul li {
    display: inline-block;
}

.footer-social ul li a {
    color: #eeeeee;
    margin: 0 5px;
}

.footer-social ul li a:hover,
.footer-contact-info ul li a:hover {
    color: #cdb30c;
}

.footer-newsletter {
    position: relative;
}

.footer-newsletter input {
    width: 100%;
    height: 64px;
    padding: 0 30px;
    border-radius: 50px;
    border: none;
}

.footer-newsletter button {
    width: 64px;
    height: 64px;
    background-color: #cdb30c;
    color: #fff;
    border: none;
    border-radius: 100px;
    position: absolute;
    top: 0;
    right: 0;
}

.footer-contact-info ul li {
    margin-bottom: 20px;
}

.footer-contact-info ul li svg {
    float: left;
    color: #cdb30c;
    margin-top: 7px;
    margin-right: 13px;
}

.footer-contact-info ul li a,
.footer-contact-info ul li span {
    display: block;
    overflow: hidden;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
}

.copyright p {
    color: #eeeeee;
    margin-bottom: 0;
}

.copyright p a {
    color: #eeeeee;
}

.copyright p a:hover {
    color: #cdb30c;
}

.footer-bottom .footer-menu li {
    display: inline-block;
    padding-left: 25px;
    color: #eeeeee;
    margin-bottom: 0;
}


/**
*   17. Breadcrumb
*/

.breadcrumb-content-wrapper h2 {
    color: #fff;
    font-size: 46px;
    line-height: 60px;
    margin-bottom: 28px;
}

.breadcrumb-content-wrapper p {
    color: #fff;
    font-size: 20px;
}

.breadcrumb-content-wrapper ul.post-meta li:after {
    background-color: #cdb30c;
}

.breadcrumb-content-wrapper ul li,
.breadcrumb-content-wrapper ul li a {
    color: #cdb30c;
}

.breadcrumb-navigation {
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
}

.breadcrumb-navigation ul li {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.breadcrumb-navigation ul li {
    display: inline-block;
    color: #fff;
}

.breadcrumb-navigation ul li:hover a {
    color: #cdb30c;
}

.breadcrumb-navigation ul li.active {
    color: #cdb30c;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: '-';
    color: #fff;
    padding: 0 10px;
}


/**
*   18. CTA
*/



.cta-wrapper h2 {
    color: #fff;
    font-size: 30px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 0;
}

.cta-wrapper h2 span a {
    color: #fff;
}

.cta-wrapper h2 span {
    font-weight: 700;
}


.cta-shape {
    position: absolute;
    bottom: 30%;
    right: 32%;
    transform: rotate(330deg);
}


/**
*   19. Widgets
*/

.widget {
    padding: 35px 20px;
    position: relative;
    background-color: #f1f1f1;
}

.widget:before {
    content: '';
    display: block;
    width: 320px;
    height: 4px;
    background-color: #cdb30c;
    position: absolute;
    top: 0;
    left: 0;
}

.widget.contact-widget:before {
    display: none;
}

.widget-title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
}

.contact-widget h3 {
    color: #fff;
}


.contact-widget {
    background-color: #523906;
    padding: 40px 40px 30px 30px;
}

.contact-widget span {
    display: block;
    font-size: 20px;
    color: #fff;
    margin-bottom: 25px;
}

.contact-widget p {
    color: #fff;
    margin-bottom: 35px;
}

.contact-widget h3 a {
    display: inline-block;
    color: #fff;
    margin-top: 35px;
}

a.l-contact-btn {
    display: inline-block;
    background-color: #cdb30c;
    color: #fff;
    padding-left: 80px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    width: 209px;
    height: 60px;
    line-height: 60px;
}

a.l-contact-btn span {
    background-color: #fff;
    border-radius: 50px;
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

a.l-contact-btn span svg {
    color: #523906;
}

.quote-widget input {
    width: 100%;
    height: 60px;
    padding: 0 22px;
    font-size: 16px;
    border: none;
    margin: 7px 0;
    background-color: #fff;
}

.quote-widget textarea {
    width: 100%;
    border: none;
    padding: 15px 22px;
    font-size: 16px;
    margin: 7px 0;
    background-color: #fff;
}

.quote-widget button {
    background-color: #cdb30c;
    color: #fff;
    padding: 15px 20px;
    display: block;
    border: none;
    width: 100%;
    text-align: left;
    line-height: 30px;
}

.quote-widget button:hover {
    background-color: #523906;
}

.quote-widget svg {
    float: right;
    margin: 7px;
}



.category-widget ul li {
    display: block;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 700;
    font-family: 'lora', serif;
    color: #523906;
}

.category-widget ul li:last-child {
    margin-bottom: 0;
}

.category-widget ul li a {
    display: inline-block;
}

.category-widget ul li:hover,
.category-widget ul li:hover a {
    color: #cdb30c;
}

.category-widget ul li span {
    float: right;
}


/**
*   20. FAQ
*/

.faq .accordion {
    border: none;
}

.faq .accordion__button:before {
    float: right;
    margin: 10px 0;
}

.faq .accordion__button {
    color: #523906;
    font-size: 20px;
    font-weight: 600;
    font-family: 'lora', serif;
    background-color: transparent;
    outline: none;
    box-shadow: none;
}

.faq .accordion__button:hover {
    background-color: none;
}

.faq .accordion__panel {
    border-top: 1px solid #e5e1da;
}

.faq .accordion-button:not(.collapsed)::after {
    transform: var(--bs-accordion-btn-icon-transform);
}

.faq h2 {
    margin-bottom: 0;
}

.accordion__item {
    border: none;
    background-color: #fff;
    color: #686868;
    margin-bottom: 10px;
    padding: 0 20px;
    box-shadow: 0px 3px 81px 0px rgb(6 6 6 / 9%);
}

.faq .accordion-button:focus {
    outline: none;
}


/**
*   21. Service/Project Details
*/

.service-details-wrapper h2 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 33px;
}

.accordion-item h2 {
    margin-bottom: 0;
    line-height: 30px;
}

.service-details-wrapper img,
.project-details-wrapper img {
    width: 100%;
}

.single-project-info {
    padding: 35px;
    text-align: center;
}

.single-project-info h6 {
    color: #fff;
}

.single-project-info span {
    display: block;
    color: #fff;
    margin-bottom: 15px;
}


/**
*   22. Pricing
*/

.single-pricing-box h3 {
    color: #fff;
    font-size: 46px;
    line-height: 70px;
}

.single-pricing-box h4 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px;
}

.single-pricing-box a {
    color: #cdb30c;
}

.single-pricing-box a svg {
    margin-left: 7px;
}

.single-pricing-box ul li {
    margin: 16px 0;
}

.single-pricing-box ul li svg {
    color: #cdb30c;
    margin-right: 10px;
}


/**
*   23. Error 404
*/

.error-404-wrapper h2 {
    font-size: 150px;
    color: #cdb30c;
}

.error-404-wrapper h3 {
    font-size: 46px;
    margin-bottom: 30px;
}

.error-404-wrapper p {
    margin-bottom: 40px;
}


/**
*   24. Blog Details
*/

.quote-block {
    margin-top: 50px;
    margin-bottom: 80px;
}

.quote-icon {
    width: 48px;
    height: 48px;
    background-color: #fff;
    color: #cdb30c;
    border-radius: 50px;
    text-align: center;
    font-size: 20px;
    line-height: 48px;
    display: inline-block;
    margin-bottom: 40px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.quote-block p {
    font-size: 20px;
    font-weight: 600;
    font-family: 'lora', serif;
    line-height: 30px;
}

.post-tags h5,
.post-share h5 {
    margin-bottom: 16px;
}

.post-tags ul li {
    display: inline-block;
}

.post-tags ul li a {
    display: inline-block;
    background-color: #f1f1f1;
    color: #523906;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
    margin-bottom: 8px;
}

.post-share ul li {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-color: #f1f1f1;
    line-height: 35px;
    text-align: center;
    border-radius: 50px;
    font-size: 14px;
}

.post-share ul li a {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #cdb30c;
    color: #fff;
    border-radius: 50px;
    line-height: 33px;
}

.post-tags ul li a:hover,
.post-share ul li a:hover {
    background-color: #523906;
    color: #fff;
}

.post-prev-btn:hover,
.post-next-btn:hover {
    background-color: #cdb30c;
    color: #fff;
    padding: 22px 36px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'lora', serif;
    border-radius: 50px;
    text-transform: uppercase;
}

.post-prev-btn,
.post-next-btn {
    color: #523906;
    padding: 22px 36px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'lora', serif;
    border-radius: 50px;
    text-transform: uppercase;
}

.post-prev-btn i {
    margin-right: 10px;
}

.post-next-btn i {
    margin-left: 10px;
}

.post-author-avatar {
    border-left: 3px solid #cdb30c;
}

.post-author-avatar img {
    border-radius: 5px;
}

.post-author-bio h5 a {
    font-size: 20px;
}

.post-author-bio h5 a:hover,
.post-author-bio a:hover {
    color: #cdb30c;
}

.post-author-bio a {
    color: #523906;
    font-size: 14px;
    margin-right: 10px;
}

.comment-body {
    background-color: #fff;
    padding: 27px;
    margin-bottom: 35px;
    box-shadow: 0px 3px 81px 0px rgb(0 0 0 / 9%);
}

.comments-area h2,
.comments-form h2 {
    font-size: 30px;
    margin-bottom: 35px;
}

.comment-avatar {
    float: left;
    margin-right: 30px;
}

.comment-content {
    overflow: hidden;
}

.comment-content h5 {
    display: inline-block;
}

.comment-content h5 a {
    font-size: 20px;
}

.comment-metadata {
    display: inline-block;
    margin-left: 10px;
}

.comment-metadata span,
.comment-metadata a {
    color: #cdb30c;
}

.comment-reply a {
    font-size: 16px;
    font-weight: 700;
    font-family: 'lora', serif;
    text-transform: uppercase;
    color: #cdb30c;
}

.comments-area li ul.children {
    padding-left: 40px;
}

.comments-form input {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    color: #523906;
    border: 1px solid #f1efeb;
    margin-bottom: 20px;
    border-radius: 5px;
}

.comments-form textarea {
    width: 100%;
    height: 120px;
    padding: 20px;
    color: #523906;
    border: 1px solid #f1efeb;
    border-radius: 5px;
    margin-bottom: 20px;
}

.comments-form input:focus,
.comments-form textarea:focus {
    border: 1px solid #cdb30c;
}

.comments-form button {
    width: 270px;
    height: 60px;
    border-radius: 5px;
    display: inline-block;
}

.comments-form button svg {
    margin-left: 10px;
}


/**
*   25. Newsletter
*/

.newsletter-content-wrapper h2 {
    font-size: 36px;
    line-height: 46px;
    color: #fff;
    margin-bottom: 60px;
}

.newsletter-content-wrapper input {
    width: 50%;
    height: 60px;
    padding: 0 30px;
    border-radius: 50px;
    border: none;
    margin-right: 20px;
}


/**
*   26. Features
*/

.single-feature {
    padding: 47px 25px;
    transition: all 0.3s ease-out 0s;
}

.single-feature img {
    margin-bottom: 25px;
}

.single-feature h5 {
    margin-bottom: 25px;
}

.single-feature:hover {
    background-color: #523906;
}

.single-feature:hover h5,
.single-feature:hover p {
    color: #fff;
}


/**
*   27. Team
*/

.team-social-style-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    width: 100%;
}


.team-social-style-2 a {
    display: inline-block;
    color: #fff;
    width: 36px;
    height: 36px;
    border: 2px solid #ddd;
    text-align: center;
    line-height: 30px;
    border-radius: 50px;
    font-size: 16px;
    margin: 0 2px;
}

.team-social-style-2 {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
}


.team-social-style-2 a:hover {
    background-color: #fff;
    color: #cdb30c;
    border-color: #fff;
}


.single-team-style-2:hover .team-social-style-2 {
    visibility: visible;
    opacity: 1;
}

.single-team-style-2 {
    position: relative;
}

.single-team-content-style-2 {
    position: absolute;
    width: 100%;
    height: 130px;
    bottom: 0;
    background-color: #523906;
    padding: 40px 0;
}

.single-team-style-2-img img {
    border-radius: 250px 250px 0px 0px;
    width: 100%;
}

.single-team-style-2:hover .single-team-style-2-img:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #3e2c07;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 250px 250px 0px 0px;
    opacity: .75;
    visibility: visible;
    transition: .3s;
}

.single-team-style-2-img:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #3e2c07;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 250px 250px 0px 0px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    overflow: hidden;
}

.single-team-content-style-2 h4 {
    color: #fff;
    font-size: 20px;
}

.single-team-content-style-2 span {
    font-size: 18px;
    color: #cdb30c;
}

.team-border-bottom {
    position: relative;
}

.team-border-bottom:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -30px;
    right: -65px;
    width: 200px;
    height: 3px;
    background-color: #edebe6;
    text-align: center;
}


/**
*   28. Quote Form
*/

.quote-title h2:after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: #cdb30c;
    margin-top: 25px;
}

.quote-title h2 {
    color: #fff;
    margin-bottom: 30px;
}

.quote-title p {
    color: #fff;
}

.book-quote-form,
.quote-area-form {
    background-color: #fff;
    padding: 50px 25px;
}

.quote-area-form h3 {
    font-size: 24px;
    margin-bottom: 40px;
}

.book-quote-form input,
.quote-area-form input,
.contact-form input {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    border: none;
    background-color: #f1f1f1;
    margin-bottom: 20px;
}

.book-quote-form textarea,
.quote-area-form textarea,
.contact-form textarea {
    width: 100%;
    height: 178px;
    background-color: #f1f1f1;
    border: none;
    padding: 20px;
    margin-bottom: 15px;
}

.book-quote-form button,
.quote-area-form button,
.contact-form button {
    width: 100%;
    border-radius: 0;
    font-weight: 400;
}


/**
*   29. Contact
*/

.contact-form .section-title span {
    margin-bottom: 20px;
}

.contact-form .l-btn {
    text-align: left;
}

.contact-form .l-btn svg {
    float: right;
}

.contact-form input,
.contact-form textarea {
    background-color: transparent;
    border: 1px solid #edebe6;
}

.contact-box span {
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 30px;
}

.contact-box h3 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 25px;
}

.contact-box p {
    color: #fff;
}

.contact-box ul li svg {
    float: left;
    color: #cdb30c;
    line-height: 26px;
    margin: 5px 15px 5px 0;
}

.contact-box ul li span {
    display: block;
    overflow: hidden;
    font-size: 18px;
    font-weight: 600;
    font-family: 'lora', serif;
    line-height: 26px;
    color: #fff;
    margin-bottom: 35px;
}

.contact-box ul li span a {
    color: #fff;
}

.social-btn-area .l-btn {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
    padding: 0;
    width: 200px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.contact-testimonial h2 {
    font-size: 30px;
    margin-bottom: 25px;
}



.map-area iframe {
    width: 100%;
    height: 300px;
    margin-bottom: 0;
}